import * as _crypto2 from "@near-js/crypto";
var _crypto = _crypto2;
try {
  if ("default" in _crypto2) _crypto = _crypto2.default;
} catch (e) {}
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PublicKey = exports.KeyType = exports.KeyPairEd25519 = exports.KeyPair = void 0;
var crypto_1 = _crypto;
Object.defineProperty(exports, "KeyPair", {
  enumerable: true,
  get: function () {
    return crypto_1.KeyPair;
  }
});
Object.defineProperty(exports, "KeyPairEd25519", {
  enumerable: true,
  get: function () {
    return crypto_1.KeyPairEd25519;
  }
});
Object.defineProperty(exports, "KeyType", {
  enumerable: true,
  get: function () {
    return crypto_1.KeyType;
  }
});
Object.defineProperty(exports, "PublicKey", {
  enumerable: true,
  get: function () {
    return crypto_1.PublicKey;
  }
});
export default exports;
export const __esModule = exports.__esModule,
  PublicKey = exports.PublicKey,
  KeyType = exports.KeyType,
  KeyPairEd25519 = exports.KeyPairEd25519,
  KeyPair = exports.KeyPair;